// NOTE: This common Address component for Add account , edit account , add store and edit store .

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useEffect } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { General_text } from '../../constant/Translation/General';
import { TYPE_CONSTANT } from '../../constant/Web_constant';

const SelectDropDown = ({
  drop_down_values, //This is drop dow values in array example:[{label:...,value:...}]
  setSelectedItem, //it will function
  selectedItem, //selected item full object example:{label:...,value:...}
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [filter_drop_down_data, setFilter_dropdown_data] = useState(null);
  const [input_value, setInputValue] = useState(
    selectedItem ? selectedItem?.label : ''
  );

  useEffect(() => {
    if (selectedItem) {
      setInputValue(selectedItem.label);
    }
  }, [selectedItem]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        isDropDownOpen && (setIsDropDownOpen(false), setIsFilter(''));
      }}
    >
      <div className="relative">
        <input
          value={input_value}
          type="text"
          className="
                    mt-0 bg-transparent
                    block
                    w-full
                    px-0.5 ltr:pr-24 rtl:pl-24
                    border-0 border-b-2 border-gray-200 transition  duration-700
                    focus:ring-0 focus:border-primary
                  "
          placeholder={General_text.general.select}
          onClick={(e) => {
            if (e.detail === 1) {
              setIsDropDownOpen(!isDropDownOpen);
            }
          }}
          onChange={(e) => {
            setIsDropDownOpen(true);
            setIsFilter(true);
            setInputValue(e.target.value);
            setFilter_dropdown_data(
              drop_down_values?.filter((item) =>
                item.label.toLowerCase().includes(e.target.value.toLowerCase())
              )
            );
            setSelectedItem(null);
          }}
        />
        <div className=" absolute bottom-0 ltr:right-2 rtl:left-2 mb-1 ">
          <div className="flex flex-row-reverse items-center gap-2">
            {isDropDownOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4.5 15.75l7.5-7.5 7.5 7.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-5 h-5"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            )}
          </div>
        </div>
        {isDropDownOpen && (
          <div className=" absolute w-full h-[200px] bg-gray-100 overflow-y-scroll border  rounded-button mt-2  z-40 shadow-md">
            {isFilter ? (
              filter_drop_down_data?.length > 0 ? (
                filter_drop_down_data?.map((filter_item) => {
                  return (
                    <div
                      key={Math.random()}
                      className={[
                        ' flex  p-2 cursor-pointer border border-transparent rounded-button hover:border-primary mt-2  shadow-sm',
                        selectedItem?.value === filter_item?.value
                          ? 'bg-primary text-white'
                          : ' text-black  bg-transparent',
                      ].join(' ')}
                      onClick={() => {
                        setSelectedItem(filter_item);
                        setInputValue(filter_item.label);
                        setIsDropDownOpen(false);
                        setIsFilter(false);
                        setFilter_dropdown_data(null);
                      }}
                    >
                      <span className=" text-sm  font-normal ltr:ml-2 rtl:mr-2">
                        {filter_item.label}
                      </span>
                    </div>
                  );
                })
              ) : (
                <div
                  className=" bg-yellow-400   text-white px-4 py-3 rounded-card  relative grid grid-cols-[10%,80%,10%]"
                  role="alert"
                >
                  <div className="flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </div>
                  <div>
                    <span className="  ltr:ml-2 rtl:mr-2">
                      {General_text.general.not_found}
                    </span>
                  </div>
                </div>
              )
            ) : drop_down_values?.length > 0 ? (
              drop_down_values?.map((filter_item) => {
                return (
                  <div
                    key={Math.random()}
                    className={[
                      ' flex  p-2 cursor-pointer border border-transparent rounded-button hover:border-primary mt-2 shadow-sm ',
                      selectedItem?.value === filter_item?.value
                        ? 'bg-primary text-white'
                        : ' text-black  bg-transparent',
                    ].join(' ')}
                    onClick={() => {
                      setSelectedItem(filter_item);
                      setIsDropDownOpen(false);
                      setInputValue(filter_item.label);
                      setIsFilter(false);
                      setFilter_dropdown_data(null);
                    }}
                  >
                    <span className=" text-sm  font-normal ltr:ml-2 rtl:mr-2">
                      {filter_item.label}
                    </span>
                  </div>
                );
              })
            ) : (
              <div
                className=" bg-yellow-400   text-white px-4 py-3 rounded-card  relative grid grid-cols-[10%,80%,10%]"
                role="alert"
              >
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </div>
                <div>
                  <span className="  ltr:ml-2 rtl:mr-2">
                    {General_text.general.not_found}
                  </span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default SelectDropDown;
