import React, { useEffect, useState } from 'react';
import { TYPE_CONSTANT } from '../../constant/Web_constant';
import { get_dynamic_styles_with_default_value } from '../../constant/DynamicStylesWithDefaultValue';
import { useScreenSize } from '../../hooks/useScreenSize';
import {
  HomeBoxDefaultStyles,
  HomeDefaultColumnStyles,
  HomeMainDefaultStyles,
} from '../../constant/default_styles/HomeDefaultStyles';
import {
  responsive_column_styles,
  responsive_design_styles,
  responsive_row_styles,
} from '../../utils/ResponsiveDesign';
import { latest_editor_version } from '../../constant/Version';
import { get_dynamic_styles } from '../../constant/DynamicStyles';
import { dynamic_card_block_decide } from './DynamicListingCardBlockDecide';
import Link from 'next/link';
import Alert from '../Shared/PopUp/Alert';
import axios from 'axios';

const DynamicListingCardLayout = ({ product, like, is_load_variants }) => {
  const dynamic_card = TYPE_CONSTANT.STYLES_CONFIGS?.dynamic_listing_card;
  const card_list_styles = dynamic_card?.styles?.list_styles;
  const { width, height } = useScreenSize();
  // States
  const [selectedVariant, setSelectedVariant] = useState(null);
  // Alert
  const [open_alert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [alert_type, setAlert_type] = useState('error');

  // variants
  const [variants, setVariants] = useState(product?.variants ?? []);
  const load_variants = async () => {
    const res = await axios.get('/api/variant/variant_by_listing', {
      params: {
        listing_id: product.id,
      },
    });

    if (res.data.variants) {
      setVariants(res.data.variants);
    }
  };

  useEffect(() => {
    if (card_list_styles?.load_variants && is_load_variants) {
      load_variants();
    }
  }, []);

  return (
    <>
      <Alert
        className={` fixed z-50 top-0 left-0 right-0  w-full  xs:w-[500px] mx-auto mt-5 `}
        type={'error'}
        open={open_alert}
        setOpen={setOpenAlert}
        isOpenFunction={undefined}
        funcCallOnClose={() => {
          setMessage('');
        }}
      >
        <p>{message}</p>
      </Alert>

      {card_list_styles?.listing_card_type == undefined ||
      card_list_styles?.listing_card_type.toLowerCase() === 'link' ? (
        <Link
          href={
            product.slug
              ? `/l/${product.slug}`
              : `/l/${product.id}-${product.title.replace(
                  /[ /?,#=]+/g,
                  '-'
                )}?id=true`
          }
        >
          <div
            className=" cursor-pointer"
            style={get_dynamic_styles_with_default_value(
              responsive_design_styles({
                width,
                parent_group: dynamic_card?.styles,
                child_group_name: 'main_styles',
              }),
              HomeMainDefaultStyles
            )}
          >
            <div
              className="flex flex-col  "
              style={get_dynamic_styles_with_default_value(
                responsive_design_styles({
                  width,
                  parent_group: dynamic_card?.styles,
                  child_group_name: 'box_styles',
                }),
                HomeBoxDefaultStyles
              )}
            >
              {dynamic_card?.schema?.map((row) => {
                const {
                  columns,
                  current_editor_version = '',
                  ...column_styles
                } = row || {};
                const row_styles = responsive_row_styles({
                  parent_group: row,
                  width,
                });
                return (
                  <div
                    className={``}
                    style={
                      current_editor_version == latest_editor_version
                        ? {
                            display: row_styles?.display,
                            gridTemplateColumns:
                              row_styles?.columns_box_percentage,
                            ...get_dynamic_styles(row_styles),
                          }
                        : {
                            ...get_dynamic_styles(row_styles),
                            display:
                              row_styles?.isShowInMobile === false
                                ? 'none'
                                : 'grid',
                            gap: row_styles?.gap_between_columns ?? '24px',
                            gridTemplateColumns:
                              width > 768
                                ? row_styles?.columns_box_percentage
                                  ? row_styles?.columns_box_percentage
                                  : `repeat(${
                                      row.columns?.length ?? 1
                                    }, minmax(0, 1fr))`
                                : row?.['768_row_styles']
                                ? row_styles?.columns_box_percentage
                                : '100%',
                          }
                    }
                  >
                    {/* Columns */}
                    {columns?.map((column, index) => {
                      const current_column_style = responsive_column_styles({
                        parent_group: column_styles,
                        index,
                        width,
                      });

                      return (
                        <div
                          className="flex   "
                          style={{
                            ...get_dynamic_styles_with_default_value(
                              current_column_style,
                              HomeDefaultColumnStyles
                            ),
                          }}
                        >
                          {column?.map((item) => {
                            return (
                              dynamic_card_block_decide({
                                ...item,
                                screen_width: width,
                                product,
                                like,
                              }) && (
                                <>
                                  {dynamic_card_block_decide({
                                    ...item,
                                    screen_width: width,
                                    product: { ...product, variants },
                                    like,
                                    selectedVariant,
                                    setSelectedVariant,
                                    setAlert_type,
                                    setMessage,
                                    setOpenAlert,
                                    variants,
                                  })}
                                </>
                              )
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </Link>
      ) : (
        <div
          className=" cursor-normal"
          style={get_dynamic_styles_with_default_value(
            responsive_design_styles({
              width,
              parent_group: dynamic_card?.styles,
              child_group_name: 'main_styles',
            }),
            HomeMainDefaultStyles
          )}
        >
          <div
            className="flex flex-col  "
            style={get_dynamic_styles_with_default_value(
              responsive_design_styles({
                width,
                parent_group: dynamic_card?.styles,
                child_group_name: 'box_styles',
              }),
              HomeBoxDefaultStyles
            )}
          >
            {dynamic_card?.schema?.map((row) => {
              const {
                columns,
                current_editor_version = '',
                ...column_styles
              } = row || {};
              const row_styles = responsive_row_styles({
                parent_group: row,
                width,
              });
              return (
                <div
                  className={``}
                  style={
                    current_editor_version == latest_editor_version
                      ? {
                          display: row_styles?.display,
                          gridTemplateColumns:
                            row_styles?.columns_box_percentage,
                          ...get_dynamic_styles(row_styles),
                        }
                      : {
                          ...get_dynamic_styles(row_styles),
                          display:
                            row_styles?.isShowInMobile === false
                              ? 'none'
                              : 'grid',
                          gap: row_styles?.gap_between_columns ?? '24px',
                          gridTemplateColumns:
                            width > 768
                              ? row_styles?.columns_box_percentage
                                ? row_styles?.columns_box_percentage
                                : `repeat(${
                                    row.columns?.length ?? 1
                                  }, minmax(0, 1fr))`
                              : row?.['768_row_styles']
                              ? row_styles?.columns_box_percentage
                              : '100%',
                        }
                  }
                >
                  {/* Columns */}
                  {columns?.map((column, index) => {
                    const current_column_style = responsive_column_styles({
                      parent_group: column_styles,
                      index,
                      width,
                    });

                    return (
                      <div
                        className="flex   "
                        style={{
                          ...get_dynamic_styles_with_default_value(
                            current_column_style,
                            HomeDefaultColumnStyles
                          ),
                        }}
                      >
                        {column?.map((item) => {
                          return (
                            dynamic_card_block_decide({
                              ...item,
                              screen_width: width,
                              product,
                              like,
                            }) && (
                              <>
                                {dynamic_card_block_decide({
                                  ...item,
                                  screen_width: width,
                                  product: { ...product, variants },
                                  like,
                                  selectedVariant,
                                  setSelectedVariant,
                                  setAlert_type,
                                  setMessage,
                                  setOpenAlert,
                                })}
                              </>
                            )
                          );
                        })}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default DynamicListingCardLayout;
